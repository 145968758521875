.catalog-title {
  font-size: 36px;
  font-family: "PB", sans-serif;
  margin-bottom: 48px;
}

.catalog {
  display: flex;
  position: relative;

  &__products {
    margin-top: 24px;
    width: 100%;
  }

  &__pagination-next-page {
    color: #000;
    height: 54px;
    background: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: "PB", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: background 0.3s;
    margin-bottom: 50px;
    &:hover {
      color: #000;
    }
  }
}

.catalog-sorting {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000;
  margin-bottom: 24px;

  &:hover {
    color: #000;
  }
  &__icon {
    margin-right: 15px;
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    span {
      background: #B3B3B3;
      height: 2px;
    }
    span:nth-child(1) {
      width: 33%;
    }
    span:nth-child(2) {
      width: 66%;
    }
    span:nth-child(3) {
      width: 100%;
    }
  }

  &__icon.catalog-sorting__desc {
    span:nth-child(1) {
      width: 100%;
    }
    span:nth-child(3) {
      width: 33%;
    }
  }

  &__title {
    font-family: "PB", sans-serif;
    font-size: 16px;
  }
}

.catalog-filter-header {
  display: flex;
  align-items: center;
  width: 294px;
  margin-bottom: 24px;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: "PB", sans-serif;
    font-size: 16px;
  }

  &__icon {
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    span {
      background: #B3B3B3;
      height: 2px;
      position: relative;
      width: 100%;
      &:before {
        content: "";
        width: 2px;
        height: 6px;
        background: #B3B3B3;
        position: absolute;
        top: 50%;
        margin-top: -3px;
      }
    }
    span:nth-child(1)::before, span:nth-child(3)::before {
      left: 12px;
    }
    span:nth-child(2)::before {
      left: 4px;
    }
  }
}

.catalog-filter {
  width: 294px;
  padding-right: 30px;
  flex: 0 0 auto;
  margin-top: 24px;

  &__sticky {
    position: sticky;
    top: 70px;
  }

  &__form {
    padding-bottom: 30px;
  }

  &__group {
    margin-bottom: 24px;
  }

  &__group.expanded {
    .catalog-filter__checkbox-group {
      display: block;
      transition: 0.2s;
    }
  }

  &__price {
    font-size: 16px;
    font-family: "PB", sans-serif;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    margin-bottom: 18px;
  }

  &__expandable {
    display: inline-block;
    font-size: 16px;
    font-family: "PB", sans-serif;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
  }

  &__title {

    a {
      color: #000;

      &:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 2px;
        background: #000;
        left: 11px;
        top: 10px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 8px;
        background: #000;
        left: 14px;
        top: 7px;
      }
    }

    &:hover {
      color: #D20F0D;
      a {
        color: #D20F0D;

        &:before {
          background: #D20F0D;
        }
        &:after {
          background: #D20F0D;
        }
      }
    }

    &.expanded {
      a {
        color: #D20F0D;

        &:before {
          background: #D20F0D;
          transition: 0.2s;
        }
        &:after {
          background: #D20F0D;
          transform: rotate(90deg);
          transition: 0.2s;
        }
      }
    }
  }

  &__checkbox-group {
    display: none;
    padding: 18px 0 0 30px;
  }

  &__checkbox {
    margin-bottom: 16px;
    input {
      display: none;
    }
    input+label {
      padding-left: 30px;
      position: relative;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        background: #DFDFDF;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
      }
      &:after {
        content: "";
        width: 10px;
        height: 7px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 3px;
        top: 50%;
        margin-top: -3px;
        background: url(../images/chk.svg) center center no-repeat;
        background-size: contain;
      }
    }
    input:checked+label {
      &:before {
        background: #D30F0E;
      }
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__price-title {
    margin-top: 30px;
    font-size: 16px;
    font-family: "PB", sans-serif;
    padding-left: 30px;
    color: #D20F0D;
  }

  &__price-slider {
    margin: 30px 38px;
  }

  &__price-inputs-group {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 30px;
  }

  &__price-input {
    width: 90px;
    padding: 15px 7px;
    background: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #7F7F7F;
      font-size: 10px;
    }
    input[type="text"] {
      outline: medium none;
      background: none;
      border: none;
      width: 65px;
      text-align: right;
      display: block;
    }
  }

  &__buttons {
    margin-top: 50px;
    padding: 0 30px;

    button {
      width: 100%;
      max-width: 250px;
      height: 60px;
      color: #fff;
      font-family: "PB", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #D30F0E;
      border: none;
      transition: 0.3s;
    }
    a {
      border: 1px solid #CCCCCC;
      color: #999999;
      font-family: "PB", sans-serif;
      font-size: 12px;
      padding-left: 28px;
      width: 140px;
      display: flex;
      align-items: center;
      height: 44px;
      cursor: pointer;
      margin-top: 30px;
      transition: 0.3s;
      background: url(../images/reset.png) 9px 16px no-repeat;
    }
  }
}

.catalog-page-description {
  margin-top: 20px;
  &__title {
    font-weight: bold;
    margin-bottom: 20px;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .catalog {
    flex-direction: column;
  }

  .catalog-actions {
    flex-direction: column;
  }

  .catalog-sorting {
    max-width: 100%;
    width: 100%;
    background: #F3F3F3;
    height: 54px;
    justify-content: center;
    order: 1;
  }

  .catalog-filter-header {
    width: 100%;
    padding-right: 0;
    margin-bottom: 0;

    &__wrapper {
      max-width: 100%;
      width: 100%;
      margin-right: 0;
      background: #F3F3F3;
      cursor: pointer;
      height: 54px;
      justify-content: center;
      margin-top: 0;
    }
  }

  .catalog-filter {
    position: relative;
    z-index: 4;
    background: #F3F3F3;
    padding: 0;
    transition: opacity 0.3s;
    width: 100%;
    margin-top: 0;

    &__form {
      opacity: 0;
      visibility: hidden;
      display: none;
      background: #F3F3F3;

      &.active {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }

    &__price-input {
      background: #fff;
    }
    &__buttons button {
      max-width: 100%;
    }
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .catalog {
    flex-direction: column;
  }

  .catalog-sorting {
    max-width: 320px;
    width: calc(50% - 20px);
    flex: 1 1 264px;
    background: #F3F3F3;
    height: 54px;
    justify-content: center;
    margin-left: auto;
  }

  .catalog-filter-header {
    max-width: 320px;
    width: 100%;
    margin-right: 30px;
    flex: 1 1 264px;
    background: #F3F3F3;
    cursor: pointer;
    height: 54px;
    justify-content: center;
    margin-bottom: 0;
  }

  .catalog__products {
    margin-top: 0;
  }

  .catalog-filter {
    position: absolute;
    z-index: 4;
    padding: 0 20px 0 0;
    transition: opacity 0.3s;
    width: 50%;
    margin-top: 0;

    &__form {
      background: #F3F3F3;
      padding-bottom: 24px;
      opacity: 0;
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    &__price-input {
      background: #fff;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .catalog-filter {
    margin-top: 24px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .catalog-page-description {
    margin-top: 20px;
    &__title {
      font-weight: bold;
      margin-bottom: 38px;
    }
  }
}