.products {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.product-preview {
  border: 1px solid #CCCCCC;
  width: 264px;
  height: 464px;
  margin-bottom: 30px;
  padding: 66px 24px 24px;
  position: relative;
  opacity: 1;
  visibility: visible;
  transition: border 0.3s, opacity 0.3s;

  &:hover {
    border-color: #000000;
  }

  &__variants {
    position: absolute;
    width: 100%;
    padding: 10px 20px;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__variants-horizontal {
    display: flex;
  }

  &__variant {
    margin: 4px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    position: relative;

    &:hover {
      .product-preview__variant-popover {
        opacity:1;
        visibility:visible;
      }
    }
    &.active>.product-preview__variant-image {
      opacity:0.3;
    }
  }

  &__variant-popover {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    background: #000000;
    font-family: "PB", sans-serif;
    font-size: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    height: 40px;
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -44px;
    border-radius: 3px;
    z-index: 2;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      left: 50%;
      margin-left: -4px;
      bottom: -4px;
    }
  }

  &__variant-image {
    width: 100%;
    height: 100%;
    background: url(../images/nophoto.png) center center no-repeat;
    background-size: contain;
  }

  &__info {
    display: none;
    &.active {
      display: block;
    }
  }

  &__info-image {
    display: flex;
    align-items: flex-end;
    height: 194px;
    background: url(../images/nophoto.png) center center no-repeat;
    background-size: contain;
    text-decoration: none !important;
  }

  &__in-stock {
    color: #D30F0E;
    font-family: "PB", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    width: 64px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    background: #FFEE00;
  }

  &__info-name {
    margin: 20px 0;
    font-family: "PB", sans-serif;
    font-size: 16px;
    min-height: 38px;
    max-height: 38px;
    overflow: hidden;
    color: #000;
    display: block;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  &__info-price {
    font-family: "PB", sans-serif;
    font-size: 24px;
    min-height: 28px;
  }

  &__delivery-time {
    position: absolute;
    top: -20px;
    left: 0;
    line-height: 1em;
    font-size: 12px;
    color: #999;
  }

  &__actions {
    display: flex;
    margin-top: 30px;
    user-select: none;
    position: relative;
  }

  &__cart-button {
    font-family: "PB", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    background: #D30F0E;
    border: medium none;
    outline: medium none;
    color: #fff;
    width: 126px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
    z-index: 3;

    &:hover {
      background: #FF3D3D;
    }
  }

  &__cart-button-preorder {
    background: #C6C6C6;

    &:hover {
      background: #aaa;
    }
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .products {
    & .product-preview {
      margin-right: 0;
      width: 100%;

      &__cart-button {
        width: 50%;
      }
    }
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .products {
    .product-preview {
      max-width: 320px;
      width: 100%;
      margin-right: 30px;
      margin-bottom: 30px;
      flex: 1 1 264px;
    }

    & .product-preview:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .products {
    & .product-preview:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .products {
    & .product-preview:nth-child(3n+3) {
      margin-right: 0;
    }
  }
}

//@media (min-width: $breakpoint-desktop) {
//  .products {
//    & .product-preview:nth-child(4n+4) {
//      margin-right: 0;
//    }
//  }
//}